window.$ = window.jQuery = require('jquery');

require('inputmask/dist/jquery.inputmask');

import '@codevadmin/jquery-tmpl';

import { Accordion } from './modules/accordion';

let accordeon = new Accordion();

import { Favorites } from './modules/favorites';

import { AppBasket } from './modules/order/basket';

$(".hidden-menu-toogler").on("click", function () {
    $(this).closest(".headerTwo").find(".hidden-menu").toggleClass("hidden-menu_show");
});
$(".js-open-menu").on("click", function() {
    $(".js-menu").toggleClass("open");
}),
$(".js-close-menu").on("click", function() {
    $(".js-menu").removeClass("open")
}),
$(document).mouseup(function(t) {
    var e = $(".js-menu")
      , i = $(".more-feature")
      , n = $(".headerTwo");
    e.is(t.target) || 0 !== e.has(t.target).length || e.removeClass("open"),
    i.is(t.target) || 0 !== i.has(t.target).length || i.find(".modal-characterisitics").removeClass("show"),
    n.is(t.target) || 0 !== n.has(t.target).length || n.find(".hidden-menu").removeClass("hidden-menu_show")
}),
$(".hidden-menu-toogler").on("click", function() {
    $(this).closest(".headerTwo").find(".hidden-menu").toggleClass("hidden-menu_show");
})
$(".js-menu-link").on("mouseenter", function() {
    var t = this,
    p = $(this),
    f = p.attr("data-target"),
    h = setTimeout(function() {
        $(".js-menu-link").removeClass("active"),
        $(t).addClass("active"),
        $(".js-secondary-item").removeClass("active"),
        $(f).addClass("active")
    }, 200)
})
$(".js-menu-link").on("mouseleave", function() {
    clearInterval(h)
})

$(window).scroll(function() {
    700 < $(this).scrollTop() ? $("#scrollTop").fadeIn() : $("#scrollTop").fadeOut(),
    $(this).scrollTop() < 50 ? $(".downArrow").fadeIn() : $(".downArrow").fadeOut()
})
$(".downArrow").on("click", function() {
    window.scrollTo({
        top: 1100,
        behavior: "smooth"
    })
})
$(document).on("click", ".showPass", function() {
    $(this).toggleClass("show"),
    $(this).hasClass("show") ? $(this).siblings("input").attr("type", "text") : $(this).siblings("input").attr("type", "password")
})
$(function () {
    new Inputmask("+38(999)999-99-99").mask($('.phone-mask'));
});
$(document).on('click', '.accordion-item__header', function (e) {
    let accordionItem = $(this).closest('.accordion-item');
    if (!$(e.target).hasClass('repeat-order')) {
        accordeon.accordeonToggler(this)
    }
})

$(document).on('click', '[data-toggle="drop"]', function(e){
    e.preventDefault();
    let drop = $(this).closest('.drop');
    if(drop.hasClass('show')){
        drop.removeClass('show');
    } else {
        drop.addClass('show');
    }
});
$(document).on('click', function(e) {
    e.stopPropagation();
    if ($(e.target).closest(".drop_close").length) {
        $('.drop').removeClass('show');        
    }
});

$(function(){

    let favorite = new Favorites();
        favorite.init();

    // инициализация работы с корзиной
    let basketList = new AppBasket();
    
    onAjaxLoad();
});



$(document).ready(function() {
    var scrollTop = $(".to-top");
    $(window).scroll(function(){
      var topPos = $(this).scrollTop();
      if (topPos > 300) {
        $(scrollTop).css('opacity','1');
      } else {
        $(scrollTop).css('opacity','0');
      }
    }); 
    $(scrollTop).click(function() {
      $('html, body').animate({
        scrollTop: 0
      }, 500);
      return false;
    }); 
});

$(document).ready(function(){
    $('.user-panel .body .close[data-close]').on('click', function(e){
        e.preventDefault();
        $('.user-panel a[data-click]').removeClass('active');
        $('.user-panel .body div').removeClass('active');
        $('body').removeClass('fixed');
        $('#backgraund_active').remove();
        $('.user-panel-mob').removeClass('active');
    });
    
    $('.user-panel a[data-click]').on('click', function(e){
        e.preventDefault();
        let click = $(this).data('click');
        $('.user-panel a[data-click]').removeClass('active');
        $('.user-panel .body div').removeClass('active');
        $(this).addClass('active');
        $('.user-panel .body '+ click).addClass('active')
        $('.user-panel-mob').addClass('active');
        if (!$('#backgraund_active').length > 0) {
            $('body').addClass('fixed').append('<div id="backgraund_active"  onclick="$(\'.close[data-close]\').trigger(\'click\')"></div>');
        }
                    
    });

    $('.mega_menu a[data-hover]').hover(function(){
        var hover = $(this).data('hover');
        $('.menu_item').removeClass('active');
        $('.mega_menu a[data-hover]').removeClass('active');
        $('#'+ hover).addClass('active');
        $('.mega_menu a[data-hover="'+ hover +'"]').addClass('active');
    });

    $('.mob_menu_item span[data-menu]').on('click', function(){
        var element = $(this).data('menu');
        if($(element).hasClass('active')){
            $(element).removeClass('active');
        } else {
            $(element).addClass('active');
        }
    })
});

// Функция для инициализации ленивой загрузки
function initLazyLoad() {
    // Находим все изображения с классом .lazyload > source
    let lazyImages = [].slice.call(
        document.querySelectorAll(".lazyload > source")
    );

    // Проверяем поддержку IntersectionObserver
    if ("IntersectionObserver" in window && 'IntersectionObserverEntry' in window) {
        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.nextElementSibling.srcset = lazyImage.dataset.srcset;
                    lazyImage.nextElementSibling.classList.add('fade-in');
                    lazyImage.parentElement.classList.remove("lazyload");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Если IntersectionObserver не поддерживается, выполняем сразу
        let active = false;

        const lazyLoad = function () {
            if (active === false) {
                active = true;
                setTimeout(function () {
                    lazyImages.forEach(function (lazyImage) {
                        if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                            lazyImage.srcset = lazyImage.dataset.srcset;
                            lazyImage.nextElementSibling.src = lazyImage.dataset.srcset;
                            lazyImage.nextElementSibling.classList.add('fade-in');
                            lazyImage.parentElement.classList.remove("lazyload");

                            lazyImages = lazyImages.filter(function (image) {
                                return image !== lazyImage;
                            });

                            if (lazyImages.length === 0) {
                                document.removeEventListener("scroll", lazyLoad);
                                window.removeEventListener("resize", lazyLoad);
                                window.removeEventListener("orientationchange", lazyLoad);
                            }
                        }
                    });

                    active = false;
                }, 200);
            }
        };

        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
    }
}

// Инициализация ленивой загрузки при загрузке страницы
document.addEventListener("DOMContentLoaded", initLazyLoad);

function onAjaxLoad() {
    initLazyLoad();
}